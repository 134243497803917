<template>
  <div>
    <h4>Constancia electrónica de riesgos.</h4>
    <p class="mt-5">
      En RedGirasol buscamos que te sientas tranquilo, por lo que será importante que conozcas algunos riesgos que existen al invertir con nosotros. 
      <strong>Completa la constancia electrónica que se presenta a continuación</strong> para conocer sobre estos riesgos y considera que al contestar <strong>“No estoy
      de acuerdo”</strong> no podremos continuar con el proceso de verificación de tu cuenta y no podrás invertir.
    </p>
    <h4 class="mt-6">Advertencias.</h4>
    <p class="mt-6 mb-6">
      I. Es importante que antes de realizar cualquier inversión, analices los diferentes indicadores que componen cada proyecto,
      tales como la calificación, tasa, plazo, enganche, garantía, giro, entre otros.<br /><br />
      II. Una vez que inviertas en algún proyecto, no podrás retirar los fondos a menos que se cancele o no logre reunir los fondos requeridos.<br /><br />
      III. Actualmente no contamos con un mercado secundario, por lo que no existirán las condiciones para que, a través de RedGirasol,
      lleves a cabo la venta de los derechos que documenten las operaciones. Es decir, no podrás retirarte anticipadamente de los proyectos
      en los que participes.<br /><br />
      IV. En caso de que el Solicitante no pague su crédito, se presentará una demanda para recuperar el monto del crédito mediante la ejecución
      de la prenda sobre los sistemas solares financiados y en caso de existir un remanente, en efectivo. A pesar de dichos esfuerzos, existe la posibilidad
      de que el monto no sea recuperado y por consecuencia, se pierda parcial o totalmente la inversión realizada en el proyecto.<br /><br />
      V. Es posible que la información del Solicitante, con la cual realizamos el análisis de riesgo crediticio, no haya sido dictaminada por un auditor externo
      independiente. Esto significa que los riesgos publicados de los proyectos podrían ser distintos a los reales.<br /><br />
      VI. La información a la cual tendrás acceso tanto de forma inicial, como subsecuente a la etapa de fondeo, podrá ser limitada en comparación
      a lo observado en el mercado de valores.<br /><br />
      VII. Ni el Gobierno Federal ni las entidades de la administración pública paraestatal podrán responsabilizarse o garantizar los recursos de
      los Clientes que sean utilizados en las Operaciones que celebren con las ITF  o frente a otros, así como tampoco asumir alguna responsabilidad
      por las obligaciones contraídas por las  ITF o por algún Cliente frente a otro, en virtud de las Operaciones que celebren.<br /><br />
      VIII. Los rendimientos sobre las inversiones que realices no son garantizados por nosotros ni podemos asegurar que vayan a ser exitosas
      ya que los retornos se derivan exclusivamente del comportamiento de pago de los Solicitantes a los cuales les prestes recursos<br /><br />
      IX. Antes de poder invertir en los proyectos de la Red, será necesario que comprendas y aceptes los riesgos, formas y términos generales
      de la plataforma a través del cuestionario que se te presentará a continuación.
    </p>
    <vs-radio
      vs-name="warning_question_agree"
      v-model="warningQuestion"
      :vs-value="1"
      >Estoy de acuerdo</vs-radio
    >
    <vs-radio
      class="ml-3"
      :disabled="true"
      vs-name="warning_question_disagree"
      v-model="warningQuestion"
      :vs-value="0"
      >No estoy de acuerdo</vs-radio
    >
    <h4 class="mt-5">Riesgos.</h4>
    <div
      class="mt-6"
      v-for="(question, idx) in riskInformationQuestions"
      :key="'information_question_model_' + idx"
    >
      <!-- <strong>{{ question.title }}</strong
      ><br /> -->
      {{ question.subtitle }}<br />
      <vs-radio
        class="mt-3"
        :vs-name="'information_question_model_' + idx"
        v-model="question.model"
        :vs-value="1"
        >Estoy de acuerdo</vs-radio
      >
      <vs-radio
        class="ml-3"
        :disabled="true"
        :vs-name="'information_question_model_' + idx"
        v-model="question.model"
        :vs-value="0"
        >No estoy de acuerdo</vs-radio
      >
    </div>
    <h4 class="mt-5">Acerca de la inversión.</h4>
    <div
      class="mt-6"
      v-for="(question, idx) in aboutInvestmentQuestions"
      :key="'investment_question_model_' + idx"
    >
      <!-- <strong>{{ question.title }}</strong
      ><br /> -->
      {{ question.subtitle }}<br />
      <p v-if="question.subtitle2">
        <br />
        {{ question.subtitle2 }}
      </p>
      <vs-radio
        class="mt-3"
        :vs-name="'investment_question_model_' + idx"
        v-model="question.model"
        :vs-value="1"
        >Estoy de acuerdo</vs-radio
      >
      <vs-radio
        class="ml-3"
        :disabled="true"
        :vs-name="'investment_question_model_' + idx"
        v-model="question.model"
        :vs-value="0"
        >No estoy de acuerdo</vs-radio
      >
    </div>    

  </div>
</template>
<script>
export default {
  name: "LatestInvestorRisksContract",
  data() {
    return {
      warningQuestion:1,
      riskInformationQuestions: [
        {
          title: "",
          subtitle:"Las inversiones en RedGirasol no están garantizadas, por lo que podrás perder parte o todo tu dinero.",
          model: 1,
        },
        {
          title: "",
          subtitle:"No podrás disponer de tus fondos mientras tu dinero se encuentre en inversión efectiva.",
          model: 1,
        },
        {
          title: "",
          subtitle:"RedGirasol no proporciona asesoría específica sobre las inversiones.",
          model: 1,
        },
        {
          title: "",
          subtitle:"Acepto que los montos que no permanezcan en inversión efectiva no generarán rendimientos.",
          model: 1,
        },
      ],
      aboutInvestmentQuestions: [
        {
          title: "",
          subtitle:"He leído este formulario y comprendo los riesgos de llevar a cabo estas inversiones.",
          subtitle2: null,
          model: 1,
        },
        {
          title: "",
          subtitle:"Antes de invertir, deberé leer cuidadosamente la información divulgada por cada uno de los Solicitantes de financiamiento en los que considere invertir. Si no he leído o no comprendo dicha información, no debería invertir.",
          subtitle2:"Comprendo que, además de los riesgos generales de la inversión, debo leer y entender la información divulgada por los Solicitantes de financiamiento.",
          model: 1,
        },
      ],
    };
  },
  methods: {
  },
};
</script>